<template>
  <div>
    <v-card class="transparent" flat>
      <v-card-title>
        <span class="font-weight-bold">
          {{ $route.name }}
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openAddDialog()">
          <v-icon>mdi-plus</v-icon>
          {{ $t("add") }} {{ meta.single }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="mytable"
          :items-per-page="$global.state.filter.pageSize"
          :items="$global.state.ministries"
          :loading="$global.state.loading"
          :headers="headers"
          hide-default-footer
          :loading-text="$t('loading')"
          :no-data-text="$t('no-data')"
          :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
        >
         
          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="deleteItem(item.id, index)" v-on="on" icon>
                  <v-icon color="error"> delete </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('hthf') }}</span>
            </v-tooltip>


            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn @click="openEditDialog(item)" v-on="on" icon>
                  <v-icon color="info"> edit </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('edit') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Pagination />
    <Dialog />
  </div>
</template>

<script>
export default {
  components: {
    Dialog: () => import("./dialog"),
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("alism"),
          value: "name",
        },
        {
          text: this.$t('alajraaat'),
          value: "actions",
        },
      ],
    };
  },
  methods: {
    getItems() {
      this.$global.dispatch(`get${this.meta.endPoint}`,{});
    },
    deleteItem(id, i) {
      this.$service.deleteItem(id, `${this.$route.meta.endPoint}`, `delete`);
      this.$eventBus.$once(`delete`, () => {
        setTimeout(() => {
          this.getItems();
        }, 1000);
      });
    },

    
    openAddDialog() {
      var item = {
        dialogType: "add",
      };
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
    },

    openEditDialog(item) {
      item.dialogType = "edit";
      this.$store.commit("setItemDetails", item);
      this.$store.commit("setDialog");
      this.$eventBus.$emit("fill-fields");
    },
  },
  mounted() {
    this.getItems();
    this.$eventBus.$on(`refresh`, () => {
      this.getItems();
    });
  },

  beforeDestroy() {
    this.$eventBus.$off(`refresh`);
    this.$eventBus.$off(`delete`);
    this.$eventBus.$off(`active`);
  },
};
</script>
